.loader {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1002;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  transition: 1s opacity;
  &.white-background {
    background-color: white;
  }
  &.scene {
    background-color: transparent;
  }
  &.loader.none {
    opacity: 0;
    & .loader-image-container {
      opacity: 0;
    }
  }
  &.show {
    opacity: 1;
  }
  & .loader-image-container {
    background-color: white;
    height: 100px;
    width: 100px;
    border-radius: 50%;
  }
  &.display-none {
    display: 'none';
  }
}

.three-sixty-blur {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5);
  // -webkit-backdrop-filter: blur(25px);
  // backdrop-filter: blur(25px);
  opacity: 1;
  transition: 1s opacity;
  &.none {
    opacity: 0;
  }
}
