@import url('https://fonts.googleapis.com/css?family=Roboto');

body,
html {
  width: 100%;
  height: 100%;
  margin: 0;
  overflow: hidden;
  font-family: serif;
  background-color: transparent;
  color: rgba(0, 0, 0, 0.77);
  font-family: 'Roboto', sans-serif;
  background: #fafafa;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  width: 100%;
  position: fixed;
}

#root .blur {
  filter: blur(25px);
  -webkit-filter: blur(25px);
}

.blur {
  filter: blur(25px);
  -webkit-filter: blur(25px);
}

.display-none {
  display: none !important;
  width: 0px;
  transition: all 0.4s ease-in-out;
}
