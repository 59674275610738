.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.feature-container {
  position: fixed;
  width: 200px;
  height: 40px;
  z-index: 1000;
  opacity: 1;
  transition: all 0.3s ease-in-out;
}
.feature-container.hide {
  opacity: 0;
}

.feature-container.autoplay {
  bottom: 16px;
  left: 16px;
}

.feature-container.cardboard {
  bottom: calc(3% + 47px);
  left: 16px;
}

.feature-container .autoplay-icon,
.feature-container .cardboard-icon {
  width: 30px;
  height: 30px;
  object-fit: contain;
  z-index: 1000;
}

.feature-container .feature-tooltip {
  opacity: 0;
  border-radius: 20px;
  height: 30px;
  width: 130px;
  z-index: 1000;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  background-color: rgba(0, 0, 0, 0.3);
  margin-left: 10px;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.feature-container .feature-tooltip.active {
  animation: fade-in 1s ease-in-out forwards;
}

.feature-container .feature-tooltip.inactive {
  animation: fade-out 2s ease-in-out forwards;
}

.cardboard-tooltip {
  opacity: 0;
  position: fixed;
  z-index: 1000;
  bottom: calc(3% + 27px);
  left: 56px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #ffffff;
  width: 230px;
  height: 90px;
  border-radius: 20px;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  background-color: rgba(0, 0, 0, 0.3);
}

.cardboard-tooltip span {
  padding: 20px;
}

.cardboard-tooltip.active {
  animation: fade-in 1s ease-in-out forwards;
}

.cardboard-tooltip.inactive {
  animation: fade-out 5s ease-in-out forwards;
}

.autoplay-icon-tablet {
  position: fixed;
  width: 30px;
  height: 30px;
  object-fit: contain;
  z-index: 1000;
  bottom: 16px;
  left: 16px;
}
#dropdown-basic-button {
  position: absolute;
  top: 120px;
  right: 10px;
  z-index: 1000;
  background-color: #ed6b6a;
  border-color: #ed6b6a;
}

.btn-stop-tour {
  position: absolute;
  top: 170px;
  right: 10px;
  z-index: 1000;
}

.shopping-icon {
  position: fixed;
  width: 40px;
  height: 40px;
  object-fit: contain;
  z-index: 1007;
  top: 5%;
  right: 15px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  cursor: pointer;
}

.shop-icon-tablet {
  position: fixed;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.8);
  object-fit: contain;
  z-index: 1000;
  bottom: 3%;
  left: 22px;
  cursor: pointer;
}

.shop-icon-tablet.active {
  top: 40%;
}

@media (orientation: landscape) {
  .shop-icon-tablet.active {
    top: 40%;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .shop-icon-tablet.active {
    top: 27%;
  }
}

@media (orientation: portrait) {
  .shop-icon-tablet.active {
    top: 55%;
  }
}

.shop-icon-desktop {
  position: fixed;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.8);
  object-fit: contain;
  z-index: 1000;
  bottom: 5%;
  left: 22px;
  cursor: pointer;
}

.shop-icon-desktop.active {
  top: 5%;
}

.shopping-car-menu {
  width: 95%;
  background: #fafafa;
  z-index: 1020;
  bottom: 0;
  left: 0;
  margin: 0 auto;
}

.shopping-car-menu .title {
  font-family: 'Roboto';
  font-size: 28px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #4a4a4a;
  padding-left: 20px;
  padding-top: 30px;
}

.shopping-car-menu .items-container {
  position: relative;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  transition: all 0.2s;
  transform: scale(0.98);
  will-change: transform;
  user-select: none;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
}

.shopping-car-menu .items-container::-webkit-scrollbar {
  display: none;
}

.shopping-car-menu .items-container.active {
  background: rgba(255, 255, 255, 0.3);
  cursor: grabbing;
  cursor: -webkit-grabbing;
  /*transform: scale(1);*/
}

.btn.login-button.btn-primary {
  background-color: #000000;
  border-color: #000000;
  border-radius: 20px;
  width: 70%;
}

.modal .modal-body,
.modal .modal-content,
.modal .modal-dialog {
  width: 323px;
  height: 275px;
  border-radius: 20px;
  margin: 5px auto;
  box-shadow: 0 4px 6px 0 rgba(138, 138, 138, 0.5);
  background-color: #fafafa;
}

.modal-dialog-centered {
  min-height: 275px;
}

.modal-dialog-centered:before {
  height: 275px;
}

.scroll-items {
  width: 160%;
}

.shopping-car-menu .shopping-car-item {
  width: 100%;
  height: 240px;
  position: relative;
  display: inline-block;
  border-radius: 6px;
  box-shadow: 0 1px 2px 0 rgba(138, 138, 138, 0.39),
    0 1px 2px 0 rgba(138, 138, 138, 0.5);
  background-color: #ffffff;
  margin-bottom: 16px;
}

@media screen and (orientation: landscape) {
  .shopping-car-menu .shopping-car-item {
    width: 49%;
  }

  .shopping-car-menu .items-container {
    justify-content: space-between;
  }
}

.shopping-car-menu .shopping-car-item .item {
  width: 100%;
  height: 100%;
}

.shopping-car-menu .shopping-car-item.desktop {
  display: inline-block;
}

.shopping-car-menu .shopping-car-item.mobile {
  display: inline-block;
}

.shopping-car-menu .shopping-car-item .fav-icon {
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 1000;
}

.shopping-car-menu .shopping-car-item .image {
  width: 188px;
  height: 121px;
  object-fit: contain;
}

.shopping-car-menu .shopping-car-item .image img {
  width: 100%;
  height: 100%;
}
.shopping-car-menu .shopping-car-item .name {
  width: 100%;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #000000;
  padding-top: 16px;
  padding-left: 16px;
}

.shopping-car-menu .shopping-car-item .retailer {
  width: 100%;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.14;
  letter-spacing: normal;
  color: #6c6c6c;
  padding-left: 16px;
  padding-bottom: 11px;
  padding-top: 5px;
}

.shopping-car-menu .shopping-car-item .colors {
  width: 100%;
  padding-left: 11px;
  height: 110px;
  overflow: auto;
}

.shopping-car-menu .shopping-car-item .colors .color-circle-container {
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 5px 0;
}

.shopping-car-menu .shopping-car-item .colors .color-circle-container.active,
.shopping-car-menu .shopping-car-item .colors .color-circle-container:hover {
  border: solid 2px #000000;
}

.shopping-car-item .footer {
  width: 100%;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  height: 70px;
  background-color: #252525;
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #ffffff;
}

.shopping-car-item .footer .price {
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  color: #ffffff;
  padding-left: 16px;
}

.shopping-car-item .footer .currency {
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #ffffff;
}

@media (max-width: 576px) {
  .shop-icon-desktop {
    display: none;
  }

  .shopping-car-menu .title {
    display: none;
  }

  /* .shopping-car-menu .items-container {
    margin-top: 10%;
  } */

  .shopping-car-menu .shopping-car-item {
    width: 100%;
    height: 240px;
    min-height: 240px;
    position: relative;
    display: inline-block;
    border-radius: 6px;
    box-shadow: 0 1px 2px 0 rgba(138, 138, 138, 0.39),
      0 1px 2px 0 rgba(138, 138, 138, 0.5);
    background-color: #ffffff;
  }

  .shopping-car-menu .shopping-car-item.desktop {
    display: none;
  }

  /* .shopping-car-menu .shopping-car-item.mobile {
    display: inline-block;
  } */

  .shopping-car-menu .shopping-car-item .image {
    width: 188px;
    height: 121px;
    object-fit: contain;
    margin-left: 16px;
  }
}

@media only screen and (device-width: 812px) and (device-height: 375px) and (-webkit-device-pixel-ratio: 3) {
  .shopping-car-menu .shopping-car-item {
    width: 300px;
    height: 249px;
    min-height: 249px;
    min-width: 300px;
    border-radius: 20px;
    box-shadow: 0 2px 4px 0 rgba(138, 138, 138, 0.5);
    background-color: #ffffff;
    position: relative;
    display: inline-block;
    margin: 2em 1em;
  }

  /* .shopping-car-menu .items-container {
    margin-top: 22%;
  } */

  .shopping-car-menu .title {
    display: none;
  }
  .shopping-car-menu .shopping-car-item.desktop {
    display: none;
  }
  .shopping-car-menu .shopping-car-item.mobile {
    display: inline-block;
  }

  .shopping-car-menu .shopping-car-item .image {
    width: 188px;
    height: 121px;
    object-fit: contain;
  }

  .shopping-car-item .footer .price {
    padding-left: 20px;
  }
}

@media screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
  .shopping-car-menu .shopping-car-item {
    width: 385px;
    height: 249px;
    min-height: 249px;
    min-width: 385px;
    border-radius: 20px;
    box-shadow: 0 2px 4px 0 rgba(138, 138, 138, 0.5);
    background-color: #ffffff;
    position: relative;
    display: inline-block;
    margin: 2em 1em;
  }
  .shopping-car-menu .shopping-car-item.desktop {
    display: none;
  }
  .shopping-car-menu .shopping-car-item.mobile {
    display: inline-block;
  }

  .shopping-car-menu .shopping-car-item .image {
    width: 188px;
    height: 121px;
    object-fit: contain;
  }

  .shopping-car-item .footer .price {
    padding-left: 20px;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.test {
  position: absolute;
  z-index: 9999;
  top: 50px;
  right: 250px;
}

#viewer {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background: #fafafa;
}

.red {
  color: red;
  font-weight: bold;
}
.blue {
  color: blue;
  font-weight: bold;
}

.hotspot {
  margin: 25px;
  width: 20px;
  position: absolute;
}

.react-joyride__beacon {
  outline: none !important;
}

@media screen and (device-width: 568px) and (device-height: 320px) and (-webkit-device-pixel-ratio: 2) {
  div.viewer-container {
    display: block !important;
  }
}

.react-joyride__beacon span:first-child {
  background-color: #ed6b6a !important;
}

.react-joyride__beacon span {
  border: 2px solid #ed6b6a !important;
}

.tooltip {
  position: absolute;
  top: 0;
  left: 0;
  padding: 20px;
  background: #4a4a4a;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  line-height: 1.5;
  letter-spacing: normal;
  border-radius: 10%;
  color: rgba(255, 255, 255);
  transform: translate3d(-50%, -100%, 0);
  opacity: 0;
  transition: opacity 0.3s, transform 0.3s;
}

.tooltip.is-active {
  opacity: 0.75;
  transform: translate3d(-50%, calc(-100% - 80px), 0);
}
